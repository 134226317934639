.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card {

  border-radius: 0% !important;
}

.sav-bg {
  background-color: #599bd7 !important;
}

.picked-bg {
  background-color: #70ad46 !important;
}

.new-bg {
  background-color: #f57836 !important;
}

.btn-bg {
  background-color: #4373c5 !important;
}

.gradient-custom {
  /* fallback for old browsers */
  /* background: #6a11cb; */

  /* Chrome 10-25, Safari 5.1-6 */
  /* background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1)); */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1)) */
}

.m-t {
  margin-top: -45px;
  right: 0;
  padding: 5px 9px 5px 8px;
  /* margin-left: 390px; */
}

.navlink {
  color: #000;
  text-decoration: none !important;
}

@import url(https://fonts.googleapis.com/css?family=Oswald:400);

/*  */

.button img {
  width: 25px;
  border-radius: 50px;
  float: left;
}

.logout {
  font-size: .8em;
  font-family: 'Oswald', sans-serif;
  position: relative;
  right: -10px;
  bottom: -4px;
  overflow: hidden;
  letter-spacing: 3px;
  opacity: 0;
  transition: opacity .45s;
  -webkit-transition: opacity .35s;

}

.button {
  text-decoration: none;
  float: right;
  /* border-radius: 30%; */
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 3px;

  margin: 8px;
  color: white;
  width: 25px;
  /* background-color: black; */
  transition: width .35s;
  -webkit-transition: width .35s;
  overflow: hidden
}


.logout-box a:hover {
  width: 100px;
  color: white;
  padding-left: 3px;

  /* width: 25px; */
  background-color: black;
  /* border-radius: 25% !important; */
}

a:hover .logout {
  opacity: .9;
}

a {
  text-decoration: none;
}

.scanned_summery {
  display: flex;
  gap: 10px;
  font-size: 18px;
  align-items: center;
  color: #1e2d37;
  padding-right: 5px;
}

div#qr-reader div span {
  display: none;
}

div#qr-reader:first-child {
  display: none;
}

div#qr-reader__scan_region,
#qr-reader__dashboard_section_csr {
  display: none !important;
}

div#qr-reader div {
  border: none !important;
}

div#qr-reader {
  border: none !important;
}

a#qr-reader__dashboard_section_swaplink {
  text-decoration: none !important;
  color: white;
  background: black;
  padding: 17px;
  display: none;
}

div#qr-reader__dashboard_section_fsr {
  margin: 25px;
}

.scanner-area {
  /* width: 100%; */
  min-height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  text-align: center;
  align-items: center;
}

.scanner-area input,
.scanner-area button {
  background: white;
  border: 1px solid grey;
  border-radius: 6px;
  min-width: 300px;
}

.scanner-area button {
  color: blue;
  /* border-radius: 12px; */
  min-width: unset;
}

.seur-scan-wrap label input {
  width: auto;
  min-width: auto;
  margin-right: 10px;
}

.tsw-list-card {
  /* min-width: 20% !important; */
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.page-link {
  cursor: default;
}
/*Scan page*/

.amira-scan-update-block, .amira-scanner-block {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  background: transparent;
  border: none;
}

button.close {
  background: transparent;
  border: none;
}

.tsw input.mb-2.en-date, .tsw .input {
  min-width: 55%;
  border-color: gray !important;
  border-radius: 4px;
  border: 1px solid;
  padding: 5px;

  color: gray;
}

input[type="search" i].search-input{
  padding-left:10px !important;
  max-width: 200px;
}
/* archive  */
tr.bg td {
  background: lightgray;
}

.loader1 {
  display: inline-block;
  font-size: 0px;
  padding: 0px;
}
.loader1 span {
  vertical-align:middle;
  border-radius:100%;
  
  display:inline-block;
  width:15px;
  height:15px;
  margin:3px 2px;
  -webkit-animation:loader1 0.8s linear infinite alternate;
  animation:loader1 0.8s linear infinite alternate;
}
.loader1 span:nth-child(1) {
  -webkit-animation-delay:-1s;
  animation-delay:-1s;
 background:rgba(93, 88, 88, 0.6);
}
.loader1 span:nth-child(2) {
  -webkit-animation-delay:-0.8s;
  animation-delay:-0.8s;
 background:rgba(93, 88, 88, 0.6);
}
.loader1 span:nth-child(3) {
  -webkit-animation-delay:-0.26666s;
  animation-delay:-0.26666s;
 background:rgba(93, 88, 88, 0.6);
}
.loader1 span:nth-child(4) {
  -webkit-animation-delay:-0.8s;
  animation-delay:-0.8s;
 background:rgba(93, 88, 88, 0.6);;
 
}
.loader1 span:nth-child(5) {
  -webkit-animation-delay:-1s;
  animation-delay:-1s;
 background:rgba(93, 88, 88, 0.6);;
}

@keyframes loader1 {
  from {transform: scale(0, 0);}
  to {transform: scale(1, 1);}
}
@-webkit-keyframes loader1 {
  from {-webkit-transform: scale(0, 0);}
  to {-webkit-transform: scale(1, 1);}
}

/*sort*/

.date-sort-arrows i {
  display: flex;
  height: 6px;
  margin-left: 4px;
}

.date-sort-arrows {
  display: initial;
  align-items: center;
  font-size: 12px;
  /* padding-top: 5px; */
  /* max-height: 24px; */
}

.date-sort-arrows span {
  cursor: pointer;
  display: block;
  height: 5px;

}

.date-sort-arrows .active {
  color: #9faab3;
  /* pointer-events: none; */
}

.clear-filter {
  font-size: 14px;
  color: dodgerblue;
  width: 25%;
  cursor:pointer
}

/* preparation model */

.tsw.preparation.modal.d-block .modal-dialog {
  max-width: 70%;
}

.tsw.preparation.modal.d-block .modal-dialog .modal-content {
  padding: 40px;
  border-radius: 60px;
}
.tsw.preparation.modal.d-block .modal-dialog .modal-content th{
  text-decoration: underline;
}
.tsw.preparation.modal.d-block .modal-dialog .modal-content td, .tsw.preparation.modal.d-block .modal-dialog .modal-content th {
  border: 0;
}

/* Text bold */
button.btn.picked-bg.mx-2.border.rounded.text-bold {
  font-weight: 500;
}

.history-date {
  font-weight: 500;
}

.action-container {
  position: absolute;
}


input[type="checkbox"] {
  cursor: pointer;
}

.tsw label.mb-2 {
  min-width: 55%;
  text-align: left;
}